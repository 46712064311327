//= ./common/pop-up.js
//= ./functions/tagCarusel.js

let button = document.querySelector('.get-audit-section__simple-btn');
let simplePopUp = document.querySelector('.simple-pop-up');

let simplePopUpInstance = simplePopUp !== null ? new togglePopUp().createPopUp(button, simplePopUp, {setText: true}) : null;

document.addEventListener('showpopup', () => {
    if(simplePopUp !== null) simplePopUpInstance.hidePopUp();
    mainPopUpInstance.showPopUp();
});



